import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/HomePage')
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 150
            }
        }
    },
    routes
})

export default router
