const defaultPayload = {
    size: 'sm', /* sm/lg/xl */
    type: 'ok', /* ok/err */
    title: '',
    body: '',
};
const popup = {

    state: {
        visible: false,
        payload: defaultPayload,
    },

    mutations: {
        showPopup(state, payload){
            state.visible = true;
            state.payload = {...state.payload, ...payload};
        },
        hidePopup(state){
            state.visible = false;
            state.payload = defaultPayload;
        }
    },

    actions: {
        showError(context, payload){
            if( payload.response && payload.response.data ) {
                payload = payload.response.data;
                if (payload.errors) {
                    let msg = '';
                    Object.keys(payload.errors).forEach((key) => {
                        payload.errors[key].forEach((item) => {
                            msg += item + "<br>";
                        });
                    });
                    context.commit('showPopup', {
                        type: 'err',
                        title: 'Błąd!',
                        body: msg,
                    });
                } else if (payload.message) {
                    context.commit('showPopup', {
                        type: 'err',
                        title: 'Błąd!',
                        body: payload.message,
                    });
                }
            }else{
                context.commit('showPopup', {
                    type: 'error',
                    title: 'Błąd!',
                    body: 'Coś poszło nie tak.',
                });
            }
        }
    },
    getters: {

    }

};

export default popup;
