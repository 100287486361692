<template>
    <div class="main-container main" id="app">
        <navigation-component/>
        <main>
            <router-view v-slot="{Component}">
                <transition name="scale-fade">
                    <component :is="Component" />
                </transition>
            </router-view>
        </main>
        <footer-component/>
    </div>
</template>

<script>
import NavigationComponent from "@/components/NavigationComponent";
import FooterComponent from "@/components/FooterComponent";
import AOS from 'aos'
import Axios from "axios";

export default {
    components: {
        FooterComponent,
        NavigationComponent
    },
    provide:{
        publicPath: process.env.BASE_URL,
        date: new Date().getTime(),
    },
    data(){
        return {
            linkToRulesFile: ''
        }
    },
    created() {
        Axios.get('api/application')
            .then(response => {
                this.linkToRulesFile = response.data.data['link-to-rules-file'];
            })
    },
    mounted() {
        AOS.init({})
        this.setCookiebot();
    },
    methods: {
        setCookiebot() {
            let initCookiebot = document.createElement('script')
            initCookiebot.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
            initCookiebot.setAttribute('data-cbid', 'bdf04f8e-8146-41e3-ba36-a90e4b9ff77a')
            initCookiebot.setAttribute('type', 'text/javascript')
            initCookiebot.setAttribute('data-blockingmode', 'auto')
            document.head.appendChild(initCookiebot)
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";
</style>
