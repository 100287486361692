<template>

    <Teleport to="#app">
        <div :class="['popup', type, {'active': show}]">
            <div class="popup__bg" @click="$emit('close')"/>
            <div :class="['popup__content', size]">
                <div class="popup__close" @click="$emit('close')"/>
                <slot></slot>
            </div>
        </div>
    </Teleport>

</template>

<script>
export default {
    name: 'ModalSlot',
    emits: ["close"],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'lg' // sm/lg/xl
        },
        type: {
            type: String,
            default: 'default' // ok/err/default
        }
    }
}
</script>

<style lang="scss">

.popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: #000;
    z-index: 999999;
    visibility: hidden;
    opacity: 0;
    transition: .2s ease-out;

    &__bg {
        width: 100%;
        height: 100%;
        background: rgba(1, 79, 29, .7);
        position: absolute;
        top: 0;
        left: 0;
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAGFBMVEUAAAD///////////////////////////8jfp1fAAAAB3RSTlMA2hq+JRu9E1zjOwAAAEhJREFUCNdjcFNgAAKmFAZzIRBDsZhBsVABKCAuBMYgHpgAccDCYAGQEEgALAQUgDPgUgjFcO0IA8HCrEAexNLAYgZnsFZWEwCpGg0INV0s1QAAAABJRU5ErkJggg=='), auto;
    }

    &__close {
        width: 26px;
        height: 26px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAGFBMVEUAAAAbGxsbGxsbGxsbGxsbGxsbGxsbGxuKKAZmAAAAB3RSTlMA2hq+JRu9E1zjOwAAAEhJREFUCNdjcFNgAAKmFAZzIRBDsZhBsVABKCAuBMYgHpgAccDCYAGQEEgALAQUgDPgUgjFcO0IA8HCrEAexNLAYgZnsFZWEwCpGg0INV0s1QAAAABJRU5ErkJggg==') center no-repeat;
        position: absolute;
        top: 9px;
        right: 12px;
        cursor: pointer;
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
    }

    &.ok {
        color: #000;
        .popup__bg {
            opacity: .8;
            background: rgba(0, 0, 0, .4);
        }
    }

    &.err {
        color: #000;

        .popup__bg {
            background: rgba(183, 0, 0, .7);
        }

        .popup__title {
            color: #78000d;
        }
    }

    &__content {
        width: 100%;
        max-width: 500px;
        padding: 40px 30px;
        background: #fff;
        border-radius: 0;
        font-size: 16px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 90vh;
        overflow: auto;

        &.sm {
            max-width: 500px;
        }

        &.lg {
            max-width: 700px;
        }

        &.xl {
            max-width: 970px;
        }

        @media (max-width: 550px) {
            height: 100%;
            max-height: 100%;
            padding: 40px 10px;
            font-size: 12px;
            border-radius: 0;
        }
    }
    &.default{
        .popup__bg {
            opacity: 1;
            background: rgba(0, 0, 0, .7);
        }
        .popup__content{
            background: transparent;
            pointer-events: none;
        }
        .popup__close {
           display: none;
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }
}

</style>
