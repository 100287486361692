<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-12 text-center py-4">
          <p class="m-0 f16 fw600">
            Może Luxusu &copy; 2023
          </p>
        </div>
      </div>
    </div>
    <modal-slot :show="showPopup" type="ok" size="xl" @close="showPopup = false">
      <div class="cookies"/>
    </modal-slot>
  </footer>
</template>

<script>
import ModalSlot from "@/slots/ModalSlot";

export default {
  name: 'FooterComponent',
  components: {ModalSlot},
  data() {
    return {
      showPopup: false,
    }
  },
  mounted() {
    this.setCookiebot();
  },
  methods: {
    setCookiebot() {
      let popupCookiebot = document.createElement('script')
      popupCookiebot.setAttribute('id', 'CookieDeclaration')
      popupCookiebot.setAttribute('src', 'https://consent.cookiebot.com/bdf04f8e-8146-41e3-ba36-a90e4b9ff77a/cd.js')
      popupCookiebot.setAttribute('type', 'text/javascript')
      popupCookiebot.setAttribute('async', 'true')
      document.querySelector('.cookies')?.appendChild(popupCookiebot)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables";

footer {
  text-align: center;
  color: #fff;
  background: #000;
  position: relative;
  z-index: 3;
}
</style>
