<template>
  <div :class="['header', {'active': open}, {'scroll': scroll}]">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="logo">
              <router-link :to="{name: 'home', hash: '#start'}">
                <img src="@/assets/img/logo.png" alt="" height="500" width="890" class="img-fluid">
              </router-link>
            </div>
            <div>
              <nav>
                <ul class="list-unstyled d-xl-flex align-items-center">
                  <li>
                    <router-link :to="{name: 'home', hash: '#start'}">Start</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'home', hash: '#moze-luxusu'}">Może Luxusu</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'home', hash: '#oferta'}">Oferta</router-link>
                  </li>
                  <li>
                    <router-link :to="{name: 'home', hash: '#kontakt'}">Kontakt</router-link>
                  </li>
                  <li class="ms-xl-3">
                    <audio controls autoplay ref="audio">
                      <source src="@/assets/spa_sounds.mp3" type="audio/mpeg">
                      Your browser does not support the audio element.
                    </audio>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="d-block d-xl-none">
              <div class="hamburger" @click="open = !open"><span/><span/><span/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "NavigationComponent",
  data() {
    return {
      open: false,
      scroll: false,
    }
  },
  watch: {
    '$route': function () {
      this.open = false;
    }
  },
  mounted() {
    this.scrollEvent();
    this.$refs.audio.play();
  },
  methods: {
    scrollEvent() {
      document.addEventListener('scroll', () => {
        this.scroll = window.scrollY > 5;
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";

.header {
  width: 100%;
  padding: 15px 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 90;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);

  .logo {
    width: 100%;
    max-width: 170px;
    position: relative;
    z-index: 12;
    margin: 0 40px;
  }

  nav {
    transition: background-color .4s ease-out;

    ul {
      display: block;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        margin: 0;

        a {
          @extend .f20;
          display: block;
          padding: 7px 0;
          font-weight: 400;
          margin: 0 15px;
          color: #000000;
          transition: .4s linear;
          position: relative;
          @media (max-width: 1300px) {
            margin: 0 10px;
          }

          &:hover {
            color: $cRed;
            box-shadow: 0 2px 0 0 $cRed;
          }
        }
      }
    }
  }

  .hamburger {
    width: 40px;
    height: 19px;
    z-index: 99;
    cursor: pointer;
    display: none;
    position: relative;
    transition: .4s ease-out;

    span {
      width: 100%;
      height: 2px;
      background: #000;
      display: block;
      position: absolute;
      left: 0;
      transition: .4s ease-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 8px;
      }

      &:nth-child(3) {
        top: 16px;
      }
    }
  }

  &.active {
    .hamburger span {
      &:nth-child(1) {
        transform: rotate(45deg) translate(8px, 4px);
      }

      &:nth-child(2) {
        width: 0;
        transform: translate(-18px, 0);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(8px, -3px);
      }
    }
  }

  @media (max-width: 1199px) {
    .logo {
      width: 100%;
      max-width: 120px;
      position: relative;
      z-index: 12;
      margin: 0 40px 0 0;
    }
    .products-navigation {
      display: none;
    }
    nav {
      width: 100vw;
      height: 100vh;
      padding-top: 20px;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      transform: scale(3);
      transform-origin: 50% 50%;
      visibility: hidden;
      transition: .4s ease-out;

      ul {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        li {
          display: block;
          text-align: center;

          a {
            background: transparent;
            padding: 5px 20px;
            margin: 5px 0;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
    .hamburger {
      display: block;
    }
    &.active {
      nav {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }
}
</style>
