import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag"
import { createGtm } from '@gtm-support/vue-gtm';
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueMask from '@devindex/vue-mask'
import Markdown from 'vue3-markdown-it';

createApp(App)
    .use(router)
    .use(createGtm({
        id: ['GTM-M4Q9M56'],
        defer: false,
        compatibility: false,
        enabled: true,
        vueRouter: router,
    }))
    .use(Markdown)
    .use(VueMask)
    .use(VueDOMPurifyHTML)
    .use(VueGtag, {config: { id: 'G-H4PNB1ZESS' }, router})
    .use(store)
    .mount('#app')
